module.exports={
    "$schema": "json-schema.org/draft-04/schema",
    "title": "Library Non CRM",
    "schemaType": "LibraryNonCrm",
    "type": "object",
    "properties": {
        "id": {
            "description": "Unique identifier for the Library record",
            "type": "string"
        },
        "crmId": {
            "description": "The id from the CRM database",
            "type": "string"
        },
        "fte": {
            "description": "Full-time Enrollment of Library",
            "type": "integer"
        },
        "fteInfo": {
            "description": "Extra information to be displayed near the FTE (e.g. to what time span it applies)",
            "type": "string"
        },
        "gar": {
            "description": "General Account Receivable.  Required for the Banner feed export.",
            "type": "string"
        },
        "excludeFromBannerFeed": {
            "description": "If true do not include this library in Banner feed export.",
            "type": "boolean"
        },
        "ipAddresses": {
            "description": "List of IP Addresses",
            "type": "string"
        },
        "contacts": {
            "description": "List of library contacts that were added in the select system.",
            "type": "array",
            "items": { "$ref": "./LibraryContact.json" }
        }
    },
    "required": [ "crmId" ]
}
