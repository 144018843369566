module.exports={
  "title": "VendorContact",
  "schemaType": "VendorContact",
  "type": "object",
  "properties": {
    "name": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "phoneNumber": {
      "type": "string"
    },
    "contactType": {
      "type": "string",
      "enum": [
        "Billing",
        "Sales",
        "Technical",
        "Other"
      ]
    }
  },
  "required": [
    "name",
    "email",
    "contactType"
  ]
}
