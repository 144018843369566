module.exports={
    "type": "string",
    "schemaType": "ProductDetailCodes",
    "enum": [
        "USIA - Membership",
        "USIB - Database",
        "USIE - Misc.",
        "USIF - I-Share",
        "USIG - Annual Access Fees",
        "USIH - Misc License Related",
        "USII - Fiscal Database",
        "USIJ - Calendar Database",
        "USIK - SFX",
        "USIL - Membership Pre-Pay",
        "USIM - I-Share Pre-Pay",
        "USIN - Database Pre-Pay"
    ]
}
