module.exports={
  "$schema": "json-schema.org/draft-04/schema",
  "title": "Cycle",
  "schemaType": "Cycle",
  "type": "object",
  "properties": {
    "id": {
      "description": "Unique identifier for the Cycle record",
      "type": "string"
    },
    "idalId": {
      "description": "Legacy Id from IDAL mysql database",
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "databaseName": {
      "type": "string"
    },
    "cycleType": {
      "$ref": "./CycleType.json"
    },
    "year": {
      "type": "integer"
    },
    "status": {
      "description": "State of the cycle.  Human readable values are defined in the Cycle Repository",
      "type": "integer"
    },
    "isArchived": {
      "type": "boolean"
    },
    "startDateForSelections": {
      "description": "When Libraries can begin making selections",
      "$ref": "./Date.json"
    },
    "endDateForSelections": {
      "description": "Last day that Libraries can make selections",
      "$ref": "./Date.json"
    },
    "productsAvailableDate": {
      "description": "Date on which products become available for patrons",
      "$ref": "./Date.json"
    }
  },
  "required": [ "name", "cycleType", "year", "status", "isArchived" ]
}
