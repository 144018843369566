module.exports={
  "$schema": "json-schema.org/draft-04/schema",
  "title": "Offering",
  "schemaType": "Offering",
  "type": "object",
  "properties": {
    "id": {
      "description": "Unique identifier",
      "type": "string"
    },
    "cycle": {
      "description": "UUID of a Cycle document",
      "type": "string"
    },
    "library": {
      "description": "UUID of a Library document",
      "type": "string"
    },
    "product": {
      "description": "UUID of a Product document",
      "type": "string"
    },
    "vendorId": {
      "description": "UUID of a Vendor document",
      "type": "string"
    },
    "display": {
      "description": "Controls how the offer is shown to the library",
      "type": "string",
      "enum": [
        "with-price",
        "without-price",
        "none"
      ]
    },
    "internalComments": {
      "description": "Comments on the offering for internal staff use",
      "type": "string"
    },
    "libraryComments": {
      "description": "Comes from the product by default",
      "type": "string"
    },
    "vendorComments": {
      "description": "Comments left by the vendor when adjusting prices",
      "type": "object",
      "items": {
        "type": "string"
      },
      "properties": {
        "site": {
          "description": "Vendor comments for site license pricing",
          "type": "string"
        },
        "su": {
          "description": "Vendor comments",
          "type": "array",
          "items": {
            "type": "object",
            "properties": {
              "users": {
                "type": "number"
              },
              "comment": {
                "type": "string"
              }
            }
          }
        }
      }
    },
    "almaId": {
      "description": "Comes from the product by default",
      "type": "string"
    },
    "pricing": {
      "description": "An object with a site-license price and a map of su numbers to prices",
      "$ref": "./Pricing.json"
    },
    "selection": {
      "description": "If product is selected, the 'users' property corresponds to the pricing object that was selected, and the date the selection was made",
      "type": "object",
      "properties": {
        "users": {
          "oneOf": [
            {
              "type": "number"
            },
            {
              "type": "string"
            }
          ]
        },
        "datePurchased": {
          "$ref": "./Date.json"
        }
      }
    },
    "flagged": {
      "description": "If this is persisted it means the flag was cleared manually. If the property is absent the app computes the state.",
      "type": "boolean"
    },
    "flaggedReason": {
      "description": "Holds an array of reasons that the Offering was flagged",
      "type": "array",
      "items": {
        "type": "string"
      }
    },
    "siteLicensePriceUpdated": {
      "description": "Starts empty when a new cycle is created, used to track whether the vendor has updated the pricing yet",
      "$ref": "./Date.json"
    },
    "suPricesUpdated": {
      "description": "Starts empty when a new cycle is created, used to track whether the vendor has updated the pricing yet",
      "$ref": "./Date.json"
    },
    "oneTimePurchaseAnnualAccessFee": {
      "description": "If the product is a one-time purchase product, this is its annual access fee for the library",
      "type": "number"
    },
    "oneTimePurchaseInvoicedDate": {
      "description": "Tracks when (and whether) the library has been invoiced for this selection (one-time purchase products only)",
      "$ref": "./Date.json"
    },
    "funding": {
      "description": "If the product is funded by CARLI, contains the details of how",
      "type": "object",
      "properties": {
        "fundedByPercentage": {
          "description": "If true, product is funded by a percentage amount, determined by 'fundedPercent'.  If false, library pays a fixed dollar amount, determined by 'fundedPrice'",
          "type": "boolean"
        },
        "fundedPercent": {
          "description": "Amount of full price paid by CARLI, expressed as a percentage",
          "type": "number",
          "minimum": 0,
          "maximum": 100
        },
        "fundedPrice": {
          "description": "Amount of full price paid by Library, expressed in dollars",
          "type": "number"
        }
      }
    }
  },
  "required": [
    "cycle",
    "library",
    "product"
  ]
}
