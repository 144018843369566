module.exports={
    "type": "string",
    "schemaType": "CycleType",
    "enum": [
        "Calendar Year",
        "Fiscal Year",
        "One-Time Purchase",
        "Alternative Cycle"
    ]
}
