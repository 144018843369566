module.exports={
  "$schema": "json-schema.org/draft-04/schema",
  "title": "NotificationTemplate",
  "schemaType": "NotificationTemplate",
  "type": "object",
  "properties": {
    "id": {
      "description": "Unique identifier for the NotificationTemplate record",
      "type": "string"
    },
    "name": {
      "type": "string"
    },
    "subject": {
      "type": "string"
    },
    "emailBody": {
      "type": "string"
    },
    "pdfBefore": {
      "type": "string"
    },
    "pdfAfter": {
      "type": "string"
    },
    "pdfContentIsEditable": {
      "type": "boolean"
    },
    "notificationType": {
      "$ref": "./NotificationType.json"
    }
  },
  "required": [ "name", "subject", "notificationType" ]
}
