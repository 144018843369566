module.exports={
    "$schema": "json-schema.org/draft-04/schema",
    "title": "Library",
    "schemaType": "Library",
    "type": "object",
    "properties": {
        "id": {
            "description": "Unique identifier for the Library record",
            "type": "string"
        },
        "name": {
            "description": "Name of Library",
            "type": "string"
        },
        "institutionYears": {
            "description": "2 or 4 years",
            "$ref": "./InstitutionYears.json"
        },
        "institutionType": {
            "description": "public or private",
            "$ref": "./InstitutionType.json"
        },
        "membershipLevel": {
            "description": "Governing, Affiliate, or Non-Member",
            "$ref": "./MembershipLevel.json"
        },
        "isIshareMember": {
            "type": "boolean"
        },
        "isActive": {
            "description": "Is this library active?",
            "type": "boolean"
        },
        "outstandingBalances": {
            "description": "List of outstanding balances",
            "type": "array",
            "items":  { "$ref": "./OutstandingBalances.json" }
        },
        "contacts": {
            "description": "List of library contacts imported from the CRM",
            "type": "array",
            "items": { "$ref": "./LibraryContact.json" }
        }
    },
    "required": [ "name" ]
}
