module.exports={
    "$schema": "json-schema.org/draft-04/schema",
    "title": "Vendor",
    "schemaType": "Vendor",
    "type": "object",
    "properties": {
        "id": {
            "description": "Unique identifier for the Vendor record",
            "type": "string"
        },
        "name": {
            "description": "Name of Vendor",
            "type": "string"
        },
        "previousName": {
            "description": "Previous Name of Vendor",
            "type": "string"
        },
        "websiteUrl": {
            "description": "Vendor's Website",
            "type": "string"
        },
        "contacts": {
            "description": "List of vendor contacts",
            "type": "array",
            "items": { "$ref": "./VendorContact.json" }
        },
        "comments": {
            "description": "Vendor comments",
            "type": "string"
        },
        "adminModule": {
            "description": "Free field text to keep track of CARLI troubleshooting with vendor",
            "type": "string"
        },
        "isActive": {
            "description": "Is this vendor active?",
            "type": "boolean"
        },
        "mayOfferOneTimePurchaseProducts": {
            "description": "Is this vendor allowed to offer products in the One-Time Purchase cycle?",
            "type": "boolean"
        }
    },
    "required": [ "name" ]
}
